
















import { Component, Vue } from 'vue-property-decorator'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

@Component({
  components: {
    swiper, swiperSlide
  }
})
export default class LayoutPhotos extends Vue {
  move = []
  drag = false
  touch = false
  swiperOption = {
    // effect: 'fade',
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction'
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  }

  closePhotos ():void { // close photo page
    this.$emit('closePhotos')
  }

  swipe (direction:string) :void{
    console.log(direction)
  }
}

