















































































import { Component, Vue } from 'vue-property-decorator'
import LayoutAttendance from '@/components/layout/attendance.vue'
import LayoutPhotos from '@/components/layout/photos.vue'
import LayoutInformation from '@/components/layout/information.vue'

@Component({
  components: {
    LayoutAttendance, LayoutPhotos, LayoutInformation
  }
})
export default class LayoutNormal extends Vue {
    submitFlag = false
    photoFlag = false
    dialogFlag = false

    toggleSubmit (): void {
      this.submitFlag = !this.submitFlag
      if (this.submitFlag) { // submitFlag 켜지면 photoFlag 끄기
        this.photoFlag = false
      }
    }

    openSubmit (): void {
      this.submitFlag = true
    }

    submitClose (): void {
      this.submitFlag = false
    }

    togglePhotos (): void{
      this.photoFlag = !this.photoFlag
      if (this.photoFlag) { // photoFlag 켜지면 submitFlag 끄기
        this.submitFlag = false
      }
    }

    openPhotos (): void{
      this.photoFlag = true
    }

    closePhotos (): void{
      this.photoFlag = false
    }

    closeAll (): void {
      this.submitClose()
      this.closePhotos()
    }

    openMap (appName:string): void{
      this.dialogFlag = false
      let url = ''
      if (appName === 'naver') {
        url = 'http://naver.me/585jte2U'
      } else {
        url = 'http://kko.to/yN6xjj1Y5'
      }
      window.open(url, '_blank')
    }

    isMobile ():boolean {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
}
