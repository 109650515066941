
















































































import { Component, Vue } from 'vue-property-decorator'
// import { getDatabase } from 'firebase/database'
import {
  ref as rtRef,
  set
} from 'firebase/database'
import { rtdb } from '@/boot/firebase'

@Component({
  props: {
    isMobile: {
      type: Boolean
    }
  }
})
export default class LayoutAttendance extends Vue {
  numbers = [0, 1, 2, 3] // with people num list
  name = ''
  withPeopleNum = 0
  withChildNum = 0
  meal = ''
  move = ''
  closeSubmit ():void { // close submit page
    this.$emit('submitClose')
  }

  async save ():Promise<void> {
    const now = new Date()
    console.log(this.name, this.withPeopleNum, this.meal, this.move)
    try {
      await set(rtRef(rtdb, 'attendance/' + now.getTime()), {
        name: this.name,
        withPeopleNum: this.withPeopleNum,
        withChildNum: this.withChildNum,
        meal: this.meal,
        move: this.move,
        updateDt: now.toLocaleString()
      })
      alert('등록이 완료되었습니다.')
    } catch {
      alert('등록에 실패했습니다.')
    }
  }
}

