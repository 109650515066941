



import { Vue, Component } from 'vue-property-decorator'
import LayoutNormal from '@/components/layout/normal.vue'
import LayoutAuth from '@/components/layout/auth.vue'

@Component({
  components: {
    LayoutNormal, LayoutAuth
  }
})
export default class App extends Vue {
  submitFlag = false
}
