
























































































































































































































































































import { Component, Vue } from 'vue-property-decorator'

@Component({
  props: {
    isMobile: {
      type: Boolean
    }
  }
})
export default class LayoutInformation extends Vue {
  bankAccount = {
    sangwoo: process.env.VUE_APP_SANGWOO_BANK,
    sangwoo_father: process.env.VUE_APP_SANGWOO_FATHER_BANK,
    minji: process.env.VUE_APP_MINJI_BANK,
    minji_father: process.env.VUE_APP_MINJI_FATHER_BANK,
    minji_mother: process.env.VUE_APP_MINJI_MOTHER_BANK
  }

  bankName = {
    woori: '우리은행',
    kb: '국민은행',
    nonghyup: '농협'
  }

  name = {
    sangwoo_father: process.env.VUE_APP_SANGWOO_FATHER,
    sangwoo_mother: process.env.VUE_APP_SANGWOO_MOTHER,
    minji_father: process.env.VUE_APP_MINJI_FATHER,
    minji_mother: process.env.VUE_APP_MINJI_MOTHER
  }

  dialogSangwoo = false
  dialogMinji = false
  onCopy (): void {
    alert('복사되었습니다.')
  }

  onError (): void {
    alert('복사에 실패하였습니다.')
  }

  sangWooCall (): void {
    window.location.href = `tel:${process.env.VUE_APP_SANGWOO_PHONE}`
  }

  minJiCall (): void {
    window.location.href = `tel:${process.env.VUE_APP_MINJI_PHONE}`
  }
}
